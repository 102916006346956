import { FC, lazy, Suspense, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { createBrowserRouter, createRoutesFromElements, Navigate, Route, RouterProvider } from "react-router-dom";
import { useSelector } from "react-redux";
import { OrganizationRole, PublicRoutes, Routes as PrivateRoutes, UserRoles } from "../utils/appEnums/AppEnums";
import { DEFAULT_TENANT_ID, GOOGLE_MEASUREMENT_ID } from "../config/app.config";
import SuspenseSpinner from "../components/Common/SuspenseSpinner/SuspenseSpinner";
import { isAccessTokenPresent, isRefreshTokenPresent } from "../utils/AuthUtils/AuthUtils";
import NonLoggedInCreateOrganization from "../views/publicPages/NonLoggedInCreateOrganization/NonLoggedInCreateOrganization";
import versionSyncListener from "./versionSyncService/versionSyncService";
import TestProductDetailsPage from "../views/privatePages/TestProductDetailsPage/TestProductDetailsPage";

const PrivateRoute = lazy(() => import("./PrivateRoute"));
const HomePageWrapper = lazy(() => import("../views/privatePages/HomePageWrapper/HomePageWrapper"));
const ProfileInfo = lazy(() => import("../views/privatePages/ProfilePage/ProfileInfo"));
const TestProducts = lazy(() => import("../views/privatePages/TestProducts/TestProducts"));
const PrivateRouteNotEmailVerified = lazy(() => import("./PrivateRouteNotEmailVerified"));
const Welcome = lazy(() => import("../views/privatePages/WelcomePage/Welcome"));
const PublicRoute = lazy(() => import("./PublicRoute"));
const Login = lazy(() => import("../views/publicPages/Login/Login"));
const RegisterPatient = lazy(() => import("../views/publicPages/RegisterPatient/RegisterPatient"));
const RegisterPartner = lazy(() => import("../views/publicPages/RegisterPartner/RegisterPartner"));
const RegisterHealthcareProvider = lazy(() => import("../views/publicPages/RegisterHealthcareProvider/RegisterHealthcareProvider"));
const RegisterHealthcareProviderSuccess = lazy(() => import("../views/publicPages/RegisterHealthcareProviderSuccess/RegisterHealthcareProviderSuccess"));
const ContactPointVerificationResult = lazy(
    () => import("../views/publicPages/VerificationPages/ContactPointVerificationResult/ContactPointVerificationResult"),
);
const ForgotPass = lazy(() => import("../views/publicPages/ForgotPassword/ForgotPass"));
const NotFound = lazy(() => import("../views/staticPages/NotFound/NotFound"));
const TestCatalogManagement = lazy(() => import("../views/privatePages/TestCatalog/TestCatalogManager"));
const TestOfferedManagement = lazy(() => import("../views/privatePages/TestOfferedManagement/TestOfferedManagement"));
const TestProductsManagement = lazy(() => import("../views/privatePages/TestProductsManagement/TestProductsManagement"));
const SamplingKitManager = lazy(() => import("../views/privatePages/SamplingKitManager/SamplingKitManager"));
const SamplingKit = lazy(() => import("../views/privatePages/SamplingKit/SamplingKit"));
const SupplyCatalog = lazy(() => import("../views/privatePages/SupplyCatalog/SupplyCatalog"));
const SupplyProductManagement = lazy(() => import("../views/privatePages/SupplyProductManagement/SupplyProductManagement"));
const SupplyFulfillmentOrders = lazy(() => import("../views/privatePages/SupplyFulfillmentOrders/SupplyFulfillmentOrders"));
const OrganizationManagement = lazy(() => import("../views/privatePages/OrganizationManagement/OrganizationManagement"));
const AccountSettingsManager = lazy(() => import("../views/privatePages/AccountSettings/AccountSettingsManager"));
const TenantSettings = lazy(() => import("../views/privatePages/TenantPage/TenantSettings"));
// const PartnershipsManager = lazy(() => import("../views/privatePages/Partnerships/PartnershipsManager"));
const PartnershipsManagement = lazy(() => import("../views/privatePages/PartnershipManagement/PartnershipManagement"));
const Applications = lazy(() => import("../views/privatePages/Applications/Applications"));
const TestProductsPlaceOrder = lazy(() => import("../views/privatePages/TestProductsPlaceOrder/TestProductsPlaceOrder"));
const HealthProviderOrders = lazy(() => import("../views/privatePages/Orders/HealthProviderOrders/HealthProviderOrders"));
const LabRequisitions = lazy(() => import("../views/privatePages/LabRequisitions/LabRequisitions"));
const HealthcareMedicalDatabase = lazy(() => import("../views/privatePages/HealthcareMedicalDatabase/HealthcareMedicalDatabase"));
const RegisterNewKitPage = lazy(() => import("../views/privatePages/RegisterNewKitPage/RegisterNewKitPage"));
const PersonalResultDetails = lazy(() => import("../views/privatePages/PersonalResults/PersonalResultDetails"));
const PatientPlaceOrder = lazy(() => import("../views/privatePages/PatientPlaceOrder/PatientPlaceOrder"));
const ImportedPersonalResultDetails = lazy(() => import("../views/privatePages/ImportedPersonalResults/ImportedPersonalResultDetails"));
const LabRequisitionManagement = lazy(() => import("../views/privatePages/LabRequisitionManagement/LabRequisitionsManagement"));
const GoToMarketOrders = lazy(() => import("../views/privatePages/Orders/GoToMarketOrders/GoToMarketOrders"));
const CampaignsListGrid = lazy(() => import("../views/privatePages/Campaign/CampaignsListGrid"));
const CampaignDetailsPage = lazy(() => import("../views/privatePages/Campaign/components/CampaignDetailsPage/CampaignDetailsPage"));
const OrderComponent = lazy(() => import("../views/privatePages/Orders/OrderComponent"));
const ProductGroupManagement = lazy(() => import("../views/privatePages/ProductGroupManagement/ProductGroupManagement"));
const ImportAndExport = lazy(() => import("../views/privatePages/ImportAndExport/ImportAndExport"));
const Analytics = lazy(() => import("../views/privatePages/Analytics/Analytics"));
const SystemSupport = lazy(() => import("../views/privatePages/SystemSupport/SystemSupport"));
const Authorized = lazy(() => import("../views/publicPages/Authorized/Authorized"));
const InitAuth = lazy(() => import("../views/publicPages/Login/InitAuth"));
const UpdatePassword = lazy(() => import("../views/publicPages/UpdatePassword/UpdatePassword"));
const EmailVerificationPage = lazy(() => import("../views/publicPages/VerificationPages/EmailVerificationPage/EmailVerificationPage"));
const MedicalRecords = lazy(() => import("../views/privatePages/MedicalRecords/MedicalRecords"));
const DryLabTestCatalog = lazy(() => import("../views/privatePages/DryLabTestCatalog/DryLabTestCatalog"));
const DryLabTestProvider = lazy(() => import("../views/privatePages/DryLabTestOfferedManagement/DryLabTestContext"));
const DryLabTestOfferedManagement = lazy(() => import("../views/privatePages/DryLabTestOfferedManagement/DryLabTestOfferedManagement"));
const PatientDataManagement = lazy(() => import("../views/privatePages/PatientDataManagement/PatientDataManagement"));
const AcademicTest = lazy(() => import("../views/privatePages/AcademicTestAndDisease/AcademicTest/AcademicTest"));
const AcademicDisease = lazy(() => import("../views/privatePages/AcademicTestAndDisease/AcademicDisease/AcademicDisease"));
const SelectPatientPortalProvider = lazy(
    () => import("../views/privatePages/PatientDataManagement/containers/SelectPatientPortalProvider/SelectPatientPortalProvider"),
);

const WorkflowTemplates = lazy(() => import("../views/privatePages/WorkflowTemplates/WorkflowTemplates"));
const WorkflowTemplateBuilder = lazy(() => import("../views/privatePages/WorkflowTemplates/components/WorkflowTemplateBuilder/WorkflowTemplateBuilder"));
const AIBuilder = lazy(() => import("../views/privatePages/AIBuilder/AIBuilder"));
const WorkflowTemplateGroups = lazy(() => import("../views/privatePages/WorkflowTemplates/components/WorkflowTemplateLists/WorkflowTemplateGroups"));

// const PersonalAccountSettings = lazy(() => import("../views/privatePages/PersonalAccountSettings/PersonalAccountSettings"));
const AuthorizedErrorPage = lazy(() => import("../views/publicPages/Authorized/AuthorizedErrorPage"));

const PersonalResultList = lazy(() => import("../views/privatePages/PersonalResults/PersonalResultList"));
const CustomLoginResults = lazy(() => import("../views/publicPages/CustomLoginResults/CustomLoginResults"));
const VerifyPayment = lazy(() => import("../views/privatePages/VerifyPayment/VerifyPayment"));

const PatientChartView = lazy(() => import("../views/privatePages/PatientDataManagement/PatientChartView/PatientChartView"));
const CreateRequisition = lazy(() => import("../views/privatePages/PatientDataManagement/CreateRequisition/CreateRequisition"));

const PatientValidation = lazy(() => import("../views/publicPages/PatientValidation/PatientValidation"));

const exampleSpinner = <SuspenseSpinner />;

export const AppRoute: FC = () => {
    const tenant = useSelector((store: any) => store.userReducer.tenant);
    // const isHealthcareOrganization = useMemo<boolean>(() => tenant?.organization?.type.includes(OrganizationRole.HEALTH_PROVIDER), [tenant]);

    const { formatMessage } = useIntl();

    const isAuthenticated = isAccessTokenPresent() || isRefreshTokenPresent();

    const setForceUpdate = useState<boolean>(false)[1]; // NOSONAR

    const updateAuthState = () => setForceUpdate((o) => !o);

    useEffect(() => {
        // Once the user is authenticated do not enable GA again
        // HIPAA regulations are very strict about tracking users
        if (isAuthenticated) {
            window[`ga-disable-${GOOGLE_MEASUREMENT_ID}`] = isAuthenticated;
        }
    }, [isAuthenticated]);

    const routes = createBrowserRouter(
        createRoutesFromElements(
            <Route path={PrivateRoutes.BASE_PATH}>
                <Route
                    index
                    element={
                        // isHealthcareOrganization ? (
                        // <Navigate to={PrivateRoutes.HEALTHCARE_PLACE_ORDER} replace />
                        // ) : (
                        <PrivateRoute activeTabName={formatMessage({ id: "approute.tab.label-home", defaultMessage: "Home" })}>
                            <Suspense fallback={exampleSpinner}>
                                <HomePageWrapper />
                            </Suspense>
                        </PrivateRoute>
                        // )
                    }
                />
                <Route
                    path={PublicRoutes.LOGIN}
                    element={
                        <PublicRoute activeTabName={formatMessage({ id: "approute.tab.label-login", defaultMessage: "Login" })} restricted>
                            <Suspense fallback={exampleSpinner}>
                                <Login />
                            </Suspense>
                        </PublicRoute>
                    }
                />
                <Route
                    path={PublicRoutes.INIT_AUTH}
                    element={
                        <PublicRoute activeTabName={formatMessage({ id: "approute.tab.label-init", defaultMessage: "Initialize Auth Process" })} restricted>
                            <Suspense fallback={exampleSpinner}>
                                <InitAuth />
                            </Suspense>
                        </PublicRoute>
                    }
                />
                {/* AUTHORIZATION */}
                <Route
                    path={PublicRoutes.AUTHORIZED}
                    element={
                        <PublicRoute activeTabName={formatMessage({ id: "approute.tab.label-authorized", defaultMessage: "Authorized" })} restricted>
                            <Suspense fallback={exampleSpinner}>
                                <Authorized updateAuthState={updateAuthState} />
                            </Suspense>
                        </PublicRoute>
                    }
                />
                <Route
                    path={PublicRoutes.ERROR_AUTHORIZED}
                    element={
                        <Suspense fallback={exampleSpinner}>
                            <AuthorizedErrorPage />
                        </Suspense>
                    }
                />

                <Route
                    path={PublicRoutes.REGISTER_PATIENT}
                    element={
                        <PublicRoute
                            activeTabName={formatMessage({ id: "approute.tab.label-register-patient", defaultMessage: "Register Patient" })}
                            restricted
                        >
                            <Suspense fallback={exampleSpinner}>
                                <RegisterPatient />
                            </Suspense>
                        </PublicRoute>
                    }
                />
                <Route
                    path={PublicRoutes.REGISTER_HEALTHCARE_PROVIDER}
                    element={
                        <PublicRoute
                            activeTabName={formatMessage({
                                id: "approute.tab.label-register-healthcare-provider",
                                defaultMessage: "Register Healthcare Provider",
                            })}
                            restricted
                        >
                            <Suspense fallback={exampleSpinner}>
                                <RegisterHealthcareProvider />
                            </Suspense>
                        </PublicRoute>
                    }
                />
                <Route
                    path={PublicRoutes.REGISTER_HEALTHCARE_PROVIDER_SUCCESS_PAGE}
                    element={
                        <PublicRoute
                            activeTabName={formatMessage({
                                id: "approute.tab.label-register-healthcare-provider-success",
                                defaultMessage: "Register Healthcare Provider Success",
                            })}
                            restricted
                        >
                            <Suspense fallback={exampleSpinner}>
                                <RegisterHealthcareProviderSuccess />
                            </Suspense>
                        </PublicRoute>
                    }
                />
                <Route
                    path={PublicRoutes.REGISTER_PARTNER}
                    element={
                        <PublicRoute
                            activeTabName={formatMessage({
                                id: "approute.tab.label-register-partner",
                                defaultMessage: "Register Partner",
                            })}
                            restricted
                        >
                            <Suspense fallback={exampleSpinner}>
                                <RegisterPartner />
                            </Suspense>
                        </PublicRoute>
                    }
                />
                <Route
                    path={PublicRoutes.LOGIN_PERSONAL_RESULTS_LIST}
                    element={
                        isAuthenticated ? (
                            <PrivateRoute activeTabName="My Orders" isPermitted={tenant?.id === DEFAULT_TENANT_ID} forceDefaultContext>
                                <Suspense fallback={exampleSpinner}>
                                    <PersonalResultList />
                                </Suspense>
                            </PrivateRoute>
                        ) : (
                            <PublicRoute activeTabName="Login | Test Order Journey" restricted>
                                <Suspense fallback={exampleSpinner}>
                                    <CustomLoginResults />
                                </Suspense>
                            </PublicRoute>
                        )
                    }
                />

                <Route
                    path={PublicRoutes.CONTACT_POINT_VERIFICATION}
                    element={
                        <PublicRoute
                            activeTabName={formatMessage({
                                id: "approute.tab.label-contact-point-verification",
                                defaultMessage: "Contact Point Verification Result",
                            })}
                            restricted={false}
                        >
                            <Suspense fallback={exampleSpinner}>
                                <ContactPointVerificationResult />
                            </Suspense>
                        </PublicRoute>
                    }
                />
                <Route
                    path={PublicRoutes.VERIFY_EMAIL}
                    element={
                        <PublicRoute
                            activeTabName={formatMessage({ id: "approute.tab.label-email-verification", defaultMessage: "Email verification" })}
                            restricted={false}
                        >
                            <Suspense fallback={exampleSpinner}>
                                <EmailVerificationPage />
                            </Suspense>
                        </PublicRoute>
                    }
                />
                <Route
                    path={PublicRoutes.SET_UP_NEW_ORGANIZATION}
                    element={
                        <PublicRoute
                            activeTabName={formatMessage({ id: "approute.tab.label-setup-new-organization", defaultMessage: "Setup Organization" })}
                            restricted={false}
                        >
                            <Suspense fallback={exampleSpinner}>
                                <NonLoggedInCreateOrganization />
                            </Suspense>
                        </PublicRoute>
                    }
                />
                <Route
                    path={PublicRoutes.FORGOT_PASSWORD}
                    element={
                        <PublicRoute
                            activeTabName={formatMessage({ id: "approute.tab.label-forgot-password", defaultMessage: "Forgot Password" })}
                            restricted={false}
                        >
                            <Suspense fallback={exampleSpinner}>
                                <ForgotPass />
                            </Suspense>
                        </PublicRoute>
                    }
                />
                <Route
                    path={`${PublicRoutes.UPDATE_PASSWORD}/:userId/:code`}
                    element={
                        <PublicRoute
                            activeTabName={formatMessage({ id: "approute.tab.label-set-password", defaultMessage: "Set Password" })}
                            restricted={false}
                        >
                            <Suspense fallback={exampleSpinner}>
                                <UpdatePassword />
                            </Suspense>
                        </PublicRoute>
                    }
                />
                <Route
                    path={`${PublicRoutes.JOURNEY_ACCESS_VERIFICATION}`}
                    element={
                        <PublicRoute
                            activeTabName={formatMessage({
                                id: "approute.tab.label-journey-access-verification",
                                defaultMessage: "Journey Access Verification",
                            })}
                            restricted={false}
                        >
                            <Suspense fallback={exampleSpinner}>
                                <PatientValidation />
                            </Suspense>
                        </PublicRoute>
                    }
                />

                {/* HEALTH_CARE_SECTION */}
                <Route
                    path={PrivateRoutes.HEALTHCARE_PLACE_ORDER}
                    element={
                        <PrivateRoute
                            activeTabName={formatMessage({ id: "approute.tab.label-healthcare-placeorder", defaultMessage: "Healthcare / Place order" })}
                            organizationRoles={[OrganizationRole.HEALTH_PROVIDER]}
                            roles={[UserRoles.Employee, UserRoles.Manager, UserRoles.SA]}
                        >
                            <Suspense fallback={exampleSpinner}>
                                <TestProductsPlaceOrder />
                            </Suspense>
                        </PrivateRoute>
                    }
                />
                <Route
                    path={PrivateRoutes.HEALTHCARE_ORDERS}
                    element={
                        <PrivateRoute
                            activeTabName={formatMessage({ id: "approute.tab.label-healthcare-orders", defaultMessage: "Healthcare / Orders" })}
                            organizationRoles={[OrganizationRole.HEALTH_PROVIDER]}
                            roles={[UserRoles.Proctor, UserRoles.SA]}
                        >
                            <Suspense fallback={exampleSpinner}>
                                <HealthProviderOrders />
                            </Suspense>
                        </PrivateRoute>
                    }
                />
                <Route
                    path={`${PrivateRoutes.HEALTHCARE_ORDER}/:masterOrderId`}
                    element={
                        <PrivateRoute
                            activeTabName={formatMessage({ id: "approute.tab.label-healthcare-orders", defaultMessage: "Healthcare / Orders" })}
                            organizationRoles={[OrganizationRole.HEALTH_PROVIDER]}
                            roles={[UserRoles.Employee, UserRoles.Manager, UserRoles.SA]}
                        >
                            <Suspense fallback={exampleSpinner}>
                                <OrderComponent />
                            </Suspense>
                        </PrivateRoute>
                    }
                />
                <Route
                    path={PrivateRoutes.HEALTHCARE_PATIENT}
                    element={
                        <PrivateRoute
                            activeTabName={formatMessage({ id: "approute.tab.label-healthcare-patients", defaultMessage: "Healthcare / Patients" })}
                            organizationRoles={[OrganizationRole.HEALTH_PROVIDER]}
                            roles={[UserRoles.Employee, UserRoles.Manager, UserRoles.SA]}
                        >
                            <Suspense fallback={exampleSpinner}>
                                <SelectPatientPortalProvider>
                                    <PatientDataManagement key={OrganizationRole.HEALTH_PROVIDER} orgType={OrganizationRole.HEALTH_PROVIDER} />
                                </SelectPatientPortalProvider>
                            </Suspense>
                        </PrivateRoute>
                    }
                />
                <Route
                    path={PrivateRoutes.HEALTHCARE_PATIENT_DETAILS}
                    element={
                        <PrivateRoute
                            activeTabName={formatMessage({ id: "approute.tab.label-healthcare-patient", defaultMessage: "Healthcare / Patient" })}
                            activePageName={formatMessage({ id: "approute.tab.label-healthcare-patient", defaultMessage: "Healthcare / Patient" })}
                            organizationRoles={[OrganizationRole.HEALTH_PROVIDER]}
                            roles={[UserRoles.Employee, UserRoles.Manager, UserRoles.SA]}
                        >
                            <Suspense fallback={exampleSpinner}>
                                <PatientChartView orgType={OrganizationRole.HEALTH_PROVIDER} />
                            </Suspense>
                        </PrivateRoute>
                    }
                />
                <Route
                    path={PrivateRoutes.HEALTHCARE_PATIENT_CREATE_REQUISITION}
                    element={
                        <PrivateRoute
                            activeTabName={formatMessage({
                                id: "approute.tab.label-healthcare-patient-create-requisition",
                                defaultMessage: "Healthcare / Patient / Create Requisition",
                            })}
                            activePageName={formatMessage({
                                id: "approute.tab.label-healthcare-patient-create-requisition",
                                defaultMessage: "Healthcare / Patient / Create Requisition",
                            })}
                            organizationRoles={[OrganizationRole.HEALTH_PROVIDER]}
                            roles={[UserRoles.Employee, UserRoles.Manager, UserRoles.SA]}
                        >
                            <Suspense fallback={exampleSpinner}>
                                <CreateRequisition orgType={OrganizationRole.HEALTH_PROVIDER} />
                            </Suspense>
                        </PrivateRoute>
                    }
                />
                <Route
                    path={PrivateRoutes.HEALTHCARE_MEDICAL_DATABASE}
                    element={
                        <PrivateRoute
                            activeTabName={formatMessage({
                                id: "approute.tab.label-healthcare-medical-database",
                                defaultMessage: "Healthcare / Medical Database",
                            })}
                            organizationRoles={[OrganizationRole.HEALTH_PROVIDER]}
                            roles={[UserRoles.Employee, UserRoles.Manager, UserRoles.SA, UserRoles.Proctor]}
                        >
                            <Suspense fallback={exampleSpinner}>
                                <HealthcareMedicalDatabase />
                            </Suspense>
                        </PrivateRoute>
                    }
                />
                {/* </Route> */}

                {/* LAB_SECTION */}
                <Route
                    path={PrivateRoutes.TEST_CATALOG}
                    element={
                        <PrivateRoute
                            activeTabName={formatMessage({ id: "approute.tab.label-lab-test-catalog", defaultMessage: "Lab / Test Catalog" })}
                            organizationRoles={[OrganizationRole.LAB]}
                            roles={[UserRoles.Proctor, UserRoles.SA]}
                        >
                            <Suspense fallback={exampleSpinner}>
                                <TestCatalogManagement />
                            </Suspense>
                        </PrivateRoute>
                    }
                />
                <Route
                    path={PrivateRoutes.LAB_REQUISITION}
                    element={
                        <PrivateRoute
                            activeTabName={formatMessage({ id: "approute.tab.label-lab-requisitions", defaultMessage: "Lab / Requisitions" })}
                            organizationRoles={[OrganizationRole.LAB]}
                            roles={[UserRoles.Proctor, UserRoles.SA]}
                        >
                            <Suspense fallback={exampleSpinner}>
                                <LabRequisitions />
                            </Suspense>
                        </PrivateRoute>
                    }
                />
                <Route
                    path={`${PrivateRoutes.LAB_REQUISITION_DETAILS}/:requisitionId`}
                    element={
                        <PrivateRoute
                            activeTabName={formatMessage({ id: "approute.tab.label-lab-requisitions", defaultMessage: "Lab / Requisitions" })}
                            organizationRoles={[OrganizationRole.LAB]}
                            roles={[UserRoles.Proctor, UserRoles.SA]}
                            activeUiTabResourceId="active-tab.name.lab-requisition.tab-"
                        >
                            <Suspense fallback={exampleSpinner}>
                                <LabRequisitionManagement />
                            </Suspense>
                        </PrivateRoute>
                    }
                />
                <Route
                    path={`${PrivateRoutes.LAB_LAB_REQUISITION_ORDER}/:masterOrderId`}
                    element={
                        <PrivateRoute
                            activeTabName={formatMessage({
                                id: "approute.tab.label-lab-requisitions-orders",
                                defaultMessage: "Lab / Requisitions / Orders",
                            })}
                            organizationRoles={[OrganizationRole.LAB]}
                            roles={[UserRoles.Proctor, UserRoles.SA]}
                        >
                            <Suspense fallback={exampleSpinner}>
                                <OrderComponent />
                            </Suspense>
                        </PrivateRoute>
                    }
                />
                <Route
                    path={PrivateRoutes.TEST_OFFERED_CREATE}
                    element={
                        <PrivateRoute
                            activeTabName={formatMessage({ id: "approute.tab.label-create-new-test", defaultMessage: "Create New Test" })}
                            activePageName={formatMessage({ id: "approute.tab.label-create-new-test", defaultMessage: "Create New Test" })}
                            organizationRoles={[OrganizationRole.LAB]}
                            roles={[UserRoles.Proctor, UserRoles.SA]}
                        >
                            <Suspense fallback={exampleSpinner}>
                                <TestOfferedManagement />
                            </Suspense>
                        </PrivateRoute>
                    }
                />
                <Route
                    path={`${PrivateRoutes.TEST_OFFERED_EDIT}/:testId`}
                    element={
                        <PrivateRoute
                            activeTabName={formatMessage({ id: "approute.tab.label-edit-test", defaultMessage: "Edit Test" })}
                            activePageName={formatMessage({
                                id: "approute.tab.label-edit-test-test-catalog",
                                defaultMessage: "Lab / Test Catalog / Edit Test",
                            })}
                            organizationRoles={[OrganizationRole.LAB]}
                            roles={[UserRoles.Proctor, UserRoles.SA]}
                        >
                            <Suspense fallback={exampleSpinner}>
                                <TestOfferedManagement />
                            </Suspense>
                        </PrivateRoute>
                    }
                />
                <Route
                    path={PrivateRoutes.DRY_LAB_TEST_CATALOG}
                    element={
                        <PrivateRoute
                            activeTabName={formatMessage({ id: "approute.tab.label-lab-dry-processes", defaultMessage: "Lab / File Processing" })}
                            organizationRoles={[OrganizationRole.LAB]}
                            roles={[UserRoles.Proctor, UserRoles.SA]}
                        >
                            <Suspense fallback={exampleSpinner}>
                                <DryLabTestCatalog />
                            </Suspense>
                        </PrivateRoute>
                    }
                />
                <Route
                    path={PrivateRoutes.DRY_LAB_TEST_OFFERED_CREATE}
                    element={
                        <PrivateRoute
                            activeTabName={formatMessage({ id: "approute.tab.label-create-dry-processes", defaultMessage: "Create new File Processing" })}
                            activePageName={formatMessage({
                                id: "approute.tab.label-create-lab-dry-processes",
                                defaultMessage: "Lab / File Processing / Create new File Processing",
                            })}
                            organizationRoles={[OrganizationRole.LAB]}
                            roles={[UserRoles.Proctor, UserRoles.SA]}
                        >
                            <Suspense fallback={exampleSpinner}>
                                <DryLabTestProvider>
                                    <DryLabTestOfferedManagement />
                                </DryLabTestProvider>
                            </Suspense>
                        </PrivateRoute>
                    }
                />
                <Route
                    path={`${PrivateRoutes.DRY_LAB_TEST_OFFERED_EDIT}/:testId`}
                    element={
                        <PrivateRoute
                            activeTabName={formatMessage({ id: "approute.tab.label-edit-dry-processes", defaultMessage: "File Processing" })}
                            activePageName={formatMessage({
                                id: "approute.tab.label-edit-lab-dry-processes",
                                defaultMessage: "Lab / File Processing / Edit File Processing",
                            })}
                            organizationRoles={[OrganizationRole.LAB]}
                            roles={[UserRoles.Proctor, UserRoles.SA]}
                        >
                            <Suspense fallback={exampleSpinner}>
                                <DryLabTestProvider>
                                    <DryLabTestOfferedManagement />
                                </DryLabTestProvider>
                            </Suspense>
                        </PrivateRoute>
                    }
                />
                <Route
                    path={PrivateRoutes.LAB_PATIENTS}
                    element={
                        <PrivateRoute
                            activeTabName={formatMessage({ id: "approute.tab.label-lab-patients", defaultMessage: "Lab / Patients" })}
                            activePageName={formatMessage({ id: "approute.tab.label-lab-patients", defaultMessage: "Lab / Patients" })}
                            organizationRoles={[OrganizationRole.LAB]}
                            roles={[UserRoles.Proctor, UserRoles.SA]}
                        >
                            <Suspense fallback={exampleSpinner}>
                                <SelectPatientPortalProvider>
                                    <PatientDataManagement key={OrganizationRole.LAB} orgType={OrganizationRole.LAB} />
                                </SelectPatientPortalProvider>
                            </Suspense>
                        </PrivateRoute>
                    }
                />
                <Route
                    path={PrivateRoutes.LAB_PATIENT_DETAILS}
                    element={
                        <PrivateRoute
                            activeTabName={formatMessage({ id: "approute.tab.label-lab-patient", defaultMessage: "Lab / Patient" })}
                            activePageName={formatMessage({ id: "approute.tab.label-lab-patient", defaultMessage: "Lab / Patient" })}
                            organizationRoles={[OrganizationRole.LAB]}
                            roles={[UserRoles.Proctor, UserRoles.SA]}
                        >
                            <Suspense fallback={exampleSpinner}>
                                <PatientChartView orgType={OrganizationRole.LAB} />
                            </Suspense>
                        </PrivateRoute>
                    }
                />
                <Route
                    path={PrivateRoutes.LAB_PATIENT_CREATE_REQUISITION}
                    element={
                        <PrivateRoute
                            activeTabName={formatMessage({
                                id: "approute.tab.label-lab-patient-create-requisition",
                                defaultMessage: "Lab / Patient / Create Requisition",
                            })}
                            activePageName={formatMessage({
                                id: "approute.tab.label-lab-patient-create-requisition",
                                defaultMessage: "Lab / Patient / Create Requisition",
                            })}
                            organizationRoles={[OrganizationRole.LAB]}
                            roles={[UserRoles.Proctor, UserRoles.SA]}
                        >
                            <Suspense fallback={exampleSpinner}>
                                <CreateRequisition orgType={OrganizationRole.LAB} />
                            </Suspense>
                        </PrivateRoute>
                    }
                />

                {/* SUPPLIER_SECTION */}
                <Route
                    path={PrivateRoutes.SAMPLING_KIT}
                    element={
                        <PrivateRoute
                            activeTabName={formatMessage({
                                id: "approute.tab.label-supplier-collection-device",
                                defaultMessage: "Supplier / Collection Device",
                            })}
                            organizationRoles={[OrganizationRole.SUPPLIER]}
                            roles={[UserRoles.Proctor, UserRoles.SA]}
                        >
                            <Suspense fallback={exampleSpinner}>
                                <SamplingKit />
                            </Suspense>
                        </PrivateRoute>
                    }
                />
                <Route
                    path={PrivateRoutes.SAMPLING_KIT_CREATE}
                    element={
                        <PrivateRoute
                            activeTabName={formatMessage({
                                id: "approute.tab.label-supplier-create-collection-device",
                                defaultMessage: "Supplier / Collection Device / Create Collection Device",
                            })}
                            activePageName="Supplier / Collection Device / Create Collection Device"
                            organizationRoles={[OrganizationRole.SUPPLIER]}
                            roles={[UserRoles.Proctor, UserRoles.SA]}
                        >
                            <Suspense fallback={exampleSpinner}>
                                <SamplingKitManager />
                            </Suspense>
                        </PrivateRoute>
                    }
                />
                <Route
                    path={`${PrivateRoutes.SAMPLING_KIT_EDIT}/:sampleKitId`}
                    element={
                        <PrivateRoute
                            activeTabName={formatMessage({ id: "approute.tab.label-edit-collection-device", defaultMessage: "Edit Collection Device" })}
                            activePageName={formatMessage({
                                id: "approute.tab.label-supplier-edit-collection-device",
                                defaultMessage: "Supplier / Collection Device / Edit Collection Device",
                            })}
                            organizationRoles={[OrganizationRole.SUPPLIER]}
                            roles={[UserRoles.Proctor, UserRoles.SA]}
                        >
                            <Suspense fallback={exampleSpinner}>
                                <SamplingKitManager />
                            </Suspense>
                        </PrivateRoute>
                    }
                />
                <Route
                    path={PrivateRoutes.SUPPLY_CATALOG}
                    element={
                        <PrivateRoute
                            activeTabName={formatMessage({ id: "approute.tab.label-supplier-catalog", defaultMessage: "Supplier / Supply Catalog" })}
                            organizationRoles={[OrganizationRole.SUPPLIER]}
                            roles={[UserRoles.Proctor, UserRoles.SA]}
                        >
                            <Suspense fallback={exampleSpinner}>
                                <SupplyCatalog />
                            </Suspense>
                        </PrivateRoute>
                    }
                />
                <Route
                    path={PrivateRoutes.SUPPLY_CATALOG_CREATE}
                    element={
                        <PrivateRoute
                            activeTabName={formatMessage({ id: "approute.tab.label-supplier-product", defaultMessage: "Create Supply Product" })}
                            activePageName={formatMessage({
                                id: "approute.tab.label-supplier-product-create",
                                defaultMessage: "Supplier / Supply Catalog / Create Supply Product",
                            })}
                            organizationRoles={[OrganizationRole.SUPPLIER]}
                            roles={[UserRoles.Proctor, UserRoles.SA]}
                        >
                            <Suspense fallback={exampleSpinner}>
                                <SupplyProductManagement />
                            </Suspense>
                        </PrivateRoute>
                    }
                />
                <Route
                    path={`${PrivateRoutes.SUPPLY_CATALOG_EDIT}/:productId`}
                    element={
                        <PrivateRoute
                            activeTabName={formatMessage({ id: "approute.tab.label-edit-supply-product", defaultMessage: "Edit Supply Product" })}
                            activePageName={formatMessage({
                                id: "approute.tab.label-supplier-product-edit",
                                defaultMessage: "Supplier / Supply Catalog / Edit Supply Product",
                            })}
                            organizationRoles={[OrganizationRole.SUPPLIER]}
                            roles={[UserRoles.Proctor, UserRoles.SA]}
                        >
                            <Suspense fallback={exampleSpinner}>
                                <SupplyProductManagement />
                            </Suspense>
                        </PrivateRoute>
                    }
                />
                <Route
                    path={PrivateRoutes.SUPPLY_FULFILLMENTS_ORDERS}
                    element={
                        <PrivateRoute
                            activeTabName={formatMessage({
                                id: "approute.tab.label-supplier-filfillment",
                                defaultMessage: "Supplier / Fulfillment orders",
                            })}
                            activePageName={formatMessage({
                                id: "approute.tab.label-supplier-filfillment",
                                defaultMessage: "Supplier / Fulfillment orders",
                            })}
                            organizationRoles={[OrganizationRole.SUPPLIER]}
                            roles={[UserRoles.Proctor, UserRoles.SA]}
                        >
                            <Suspense fallback={exampleSpinner}>
                                <SupplyFulfillmentOrders />
                            </Suspense>
                        </PrivateRoute>
                    }
                />

                {/* GO_TO_TO_MARKET_SECTION */}
                <Route
                    path={PrivateRoutes.GO_TO_MARKET_PLACE_ORDER}
                    element={
                        <PrivateRoute
                            activeTabName={formatMessage({
                                id: "approute.tab.label-go-to-market-place-order",
                                defaultMessage: "Go to Market / Place order",
                            })}
                            organizationRoles={[OrganizationRole.GO_TO_MARKET]}
                            roles={[UserRoles.Employee, UserRoles.Manager, UserRoles.SA]}
                        >
                            <Suspense fallback={exampleSpinner}>
                                <TestProductsPlaceOrder />
                            </Suspense>
                        </PrivateRoute>
                    }
                />
                <Route
                    path={PrivateRoutes.GO_TO_MARKET_ORDERS}
                    element={
                        <PrivateRoute
                            activeTabName={formatMessage({ id: "approute.tab.label-go-to-market-orders", defaultMessage: "Go to Market / Orders" })}
                            organizationRoles={[OrganizationRole.GO_TO_MARKET]}
                            roles={[UserRoles.Proctor, UserRoles.SA]}
                        >
                            <Suspense fallback={exampleSpinner}>
                                <GoToMarketOrders />
                            </Suspense>
                        </PrivateRoute>
                    }
                />
                <Route
                    path={`${PrivateRoutes.GO_TO_MARKET_ORDER}/:masterOrderId`}
                    element={
                        <PrivateRoute
                            activeTabName={formatMessage({ id: "approute.tab.label-go-to-market-orders", defaultMessage: "Go to Market / Orders" })}
                            organizationRoles={[OrganizationRole.GO_TO_MARKET]}
                            roles={[UserRoles.Employee, UserRoles.Manager, UserRoles.SA]}
                        >
                            <Suspense fallback={exampleSpinner}>
                                <OrderComponent />
                            </Suspense>
                        </PrivateRoute>
                    }
                />
                <Route
                    path={PrivateRoutes.GO_TO_MARKET_WORKFLOW_TEMPLATES}
                    element={
                        <PrivateRoute
                            activeTabName={formatMessage({
                                id: "approute.tab.label-go-to-market-workflow-templates",
                                defaultMessage: "Go to Market / Application Builder",
                            })}
                            organizationRoles={[OrganizationRole.GO_TO_MARKET]}
                            roles={[UserRoles.Manager, UserRoles.SA]}
                        >
                            <Suspense fallback={exampleSpinner}>
                                <WorkflowTemplateGroups />
                            </Suspense>
                        </PrivateRoute>
                    }
                />
                <Route
                    path={PrivateRoutes.GO_TO_MARKET_WORKFLOW_TEMPLATES_PUBLIC}
                    element={
                        <PrivateRoute
                            activeTabName={formatMessage({
                                id: "approute.tab.label-go-to-market-workflow-templates",
                                defaultMessage: "Go to Market / Application Builder",
                            })}
                            organizationRoles={[OrganizationRole.GO_TO_MARKET]}
                            roles={[UserRoles.Manager, UserRoles.SA]}
                        >
                            <Suspense fallback={exampleSpinner}>
                                <WorkflowTemplates />
                            </Suspense>
                        </PrivateRoute>
                    }
                />
                <Route
                    path={`${PrivateRoutes.GO_TO_MARKET_WORKFLOW_TEMPLATES}/:templateId`}
                    element={
                        <PrivateRoute
                            activeTabName={formatMessage({
                                id: "approute.tab.label-go-to-market-workflow-templates",
                                defaultMessage: "Go to Market / Application templates",
                            })}
                            organizationRoles={[OrganizationRole.GO_TO_MARKET]}
                            roles={[UserRoles.Manager, UserRoles.SA]}
                        >
                            <Suspense fallback={exampleSpinner}>
                                <WorkflowTemplateBuilder />
                            </Suspense>
                        </PrivateRoute>
                    }
                />
                <Route
                    path={PrivateRoutes.GO_TO_MARKET_AI_BUILDER}
                    element={
                        <PrivateRoute
                            activeTabName={formatMessage({
                                id: "approute.tab.label-go-to-market-workflow-templates-ai-builder",
                                defaultMessage: "Go to Market / AI Application Builder",
                            })}
                            organizationRoles={[OrganizationRole.GO_TO_MARKET]}
                            roles={[UserRoles.Manager, UserRoles.SA]}
                        >
                            <Suspense fallback={exampleSpinner}>
                                <AIBuilder />
                            </Suspense>
                        </PrivateRoute>
                    }
                />
                <Route
                    path={PrivateRoutes.TEST_PRODUCT}
                    element={
                        <PrivateRoute
                            activeTabName={formatMessage({
                                id: "approute.tab.label-go-to-market-product-catalog",
                                defaultMessage: "Go To Market / Products Catalog",
                            })}
                            organizationRoles={[OrganizationRole.GO_TO_MARKET]}
                            roles={[UserRoles.Proctor, UserRoles.SA]}
                        >
                            <Suspense fallback={exampleSpinner}>
                                <TestProducts />
                            </Suspense>
                        </PrivateRoute>
                    }
                />
                <Route
                    path={PrivateRoutes.TEST_PRODUCT_CREATE}
                    element={
                        <PrivateRoute
                            activeTabName={formatMessage({ id: "approute.tab.label-create-test-product", defaultMessage: "Create New Test Product" })}
                            activePageName={formatMessage({
                                id: "approute.tab.label-go-to-market-test-product-catalog",
                                defaultMessage: "Go To Market / Test Product Catalog / Create New Test Product",
                            })}
                            organizationRoles={[OrganizationRole.GO_TO_MARKET]}
                            roles={[UserRoles.Proctor, UserRoles.SA]}
                        >
                            <Suspense fallback={exampleSpinner}>
                                <TestProductsManagement />
                            </Suspense>
                        </PrivateRoute>
                    }
                />
                <Route
                    path={`${PrivateRoutes.TEST_PRODUCT_EDIT}/:productId`}
                    element={
                        <PrivateRoute
                            activeTabName={formatMessage({ id: "approute.tab.label-edit-test-product", defaultMessage: "Edit Test Product" })}
                            activePageName={formatMessage({ id: "approute.tab.label-edit-test-product", defaultMessage: "Edit Test Product" })}
                            organizationRoles={[OrganizationRole.GO_TO_MARKET]}
                            roles={[UserRoles.Proctor, UserRoles.SA]}
                        >
                            <Suspense fallback={exampleSpinner}>
                                <TestProductsManagement />
                            </Suspense>
                        </PrivateRoute>
                    }
                />
                <Route
                    path={PrivateRoutes.GO_TO_MARKET_PRODUCT_GROUPS}
                    element={
                        <PrivateRoute
                            activeTabName={formatMessage({
                                id: "approute.tab.label-go-to-market-product-groups",
                                defaultMessage: "Go to Market / Product Sharing",
                            })}
                            organizationRoles={[OrganizationRole.GO_TO_MARKET]}
                            roles={[UserRoles.Proctor, UserRoles.SA]}
                        >
                            <Suspense fallback={exampleSpinner}>
                                <ProductGroupManagement />
                            </Suspense>
                        </PrivateRoute>
                    }
                />

                {/* TEST PRODUCT DETAILS */}

                <Route
                    path={PrivateRoutes.TEST_PRODUCT_DETAILS}
                    element={
                        <PrivateRoute
                            activeTabName={formatMessage({ id: "approute.tab.label-test-product-details", defaultMessage: "Test Product Details" })}
                            activePageName={formatMessage({ id: "approute.tab.label-test-product-details", defaultMessage: "Test Product Details" })}
                            organizationRoles={[OrganizationRole.HEALTH_PROVIDER, OrganizationRole.LAB]}
                            roles={[UserRoles.Employee, UserRoles.Manager, UserRoles.SA]}
                        >
                            <Suspense fallback={exampleSpinner}>
                                <TestProductDetailsPage />
                            </Suspense>
                        </PrivateRoute>
                    }
                />

                {/* TEST PRODUCT DETAILS */}

                {/* MEDICAL_DATABASE */}
                <Route
                    path={PrivateRoutes.ACADEMIC_TEST}
                    element={
                        <PrivateRoute
                            activeTabName={formatMessage({ id: "approute.tab.label-academic-test", defaultMessage: "Academic Test" })}
                            activePageName={formatMessage({ id: "approute.tab.label-academic-test", defaultMessage: "Academic Test" })}
                            organizationRoles={[
                                OrganizationRole.HEALTH_PROVIDER,
                                OrganizationRole.LAB,
                                OrganizationRole.SUPPLIER,
                                OrganizationRole.GO_TO_MARKET,
                            ]}
                            roles={[UserRoles.Employee, UserRoles.Staff, UserRoles.Manager, UserRoles.SA, UserRoles.Proctor]}
                        >
                            <Suspense fallback={exampleSpinner}>
                                <AcademicTest />
                            </Suspense>
                        </PrivateRoute>
                    }
                />
                <Route
                    path={PrivateRoutes.ACADEMIC_DISEASE}
                    element={
                        <PrivateRoute
                            activeTabName={formatMessage({ id: "approute.tab.label-academic-disease", defaultMessage: "Academic Disease" })}
                            activePageName={formatMessage({ id: "approute.tab.label-academic-disease", defaultMessage: "Academic Disease" })}
                            organizationRoles={[
                                OrganizationRole.HEALTH_PROVIDER,
                                OrganizationRole.LAB,
                                OrganizationRole.SUPPLIER,
                                OrganizationRole.GO_TO_MARKET,
                            ]}
                            roles={[UserRoles.Employee, UserRoles.Staff, UserRoles.Manager, UserRoles.SA, UserRoles.Proctor]}
                        >
                            <Suspense fallback={exampleSpinner}>
                                <AcademicDisease />
                            </Suspense>
                        </PrivateRoute>
                    }
                />

                <Route path={PrivateRoutes.MY_TESTS} element={<Navigate replace to={PublicRoutes.LOGIN_PERSONAL_RESULTS_LIST} />} />

                <Route
                    path={PrivateRoutes.PROFILE}
                    element={
                        <PrivateRoute
                            activeTabName={formatMessage({ id: "approute.tab.label-profile", defaultMessage: "Profile" })}
                            activeUiTabResourceId="active-tab.name.profile.tab-"
                            isPermitted={tenant?.id === DEFAULT_TENANT_ID}
                        >
                            <Suspense fallback={exampleSpinner}>
                                <ProfileInfo />
                            </Suspense>
                        </PrivateRoute>
                    }
                />

                <Route
                    path={`${PrivateRoutes.PERSONAL_TEST_RESULTS_VIEW}/:orderId`}
                    element={
                        // isPermitted={tenant?.id === DEFAULT_TENANT_ID}
                        <PrivateRoute activeTabName={formatMessage({ id: "approute.tab.label-mytests-viewresult", defaultMessage: "My tests / view result" })}>
                            <Suspense fallback={exampleSpinner}>
                                <PersonalResultDetails />
                            </Suspense>
                        </PrivateRoute>
                    }
                />
                <Route
                    path={PrivateRoutes.PERSONAL_MEDICAL_RECORDS}
                    element={
                        <PrivateRoute
                            activeTabName={formatMessage({ id: "approute.tab.label-personal-medical-records", defaultMessage: "Personal / Medical Records" })}
                        >
                            <MedicalRecords />
                        </PrivateRoute>
                    }
                />
                <Route
                    path={PrivateRoutes.PERSONAL_PLACE_ORDER}
                    element={
                        <PrivateRoute
                            activeTabName={formatMessage({ id: "approute.tab.label-personal-place-order", defaultMessage: "Personal / Place Order" })}
                        >
                            <Suspense fallback={exampleSpinner}>
                                <PatientPlaceOrder />
                            </Suspense>
                        </PrivateRoute>
                    }
                />
                <Route
                    path={PrivateRoutes.REGISTER_NEW_KIT}
                    element={
                        <PrivateRoute
                            activeTabName={formatMessage({ id: "approute.tab.label-personal-register-kit", defaultMessage: "Personal / Register New Kit" })}
                            forceDefaultContext
                        >
                            <RegisterNewKitPage />
                        </PrivateRoute>
                    }
                />
                <Route
                    path={PrivateRoutes.PERSONAL_SETTINGS}
                    element={
                        <PrivateRoute
                            activeTabName={formatMessage({ id: "approute.tab.label-personal-settings", defaultMessage: "Personal / Settings" })}
                            forceDefaultContext
                        >
                            {/* <PersonalAccountSettings /> */}
                            <Suspense fallback={exampleSpinner}>
                                <AccountSettingsManager />
                            </Suspense>
                        </PrivateRoute>
                    }
                />

                <Route
                    path={PrivateRoutes.ORGANIZATIONS_CREATE}
                    element={
                        <PrivateRoute
                            activeTabName={formatMessage({ id: "approute.tab.label-create-organization", defaultMessage: "Create New Organization" })}
                            activePageName={formatMessage({
                                id: "approute.tab.label-create-organization-page",
                                defaultMessage: "Organization / Create New Organization",
                            })}
                            roles={[UserRoles.Patient]}
                        >
                            <Suspense fallback={exampleSpinner}>
                                <OrganizationManagement />
                            </Suspense>
                        </PrivateRoute>
                    }
                />

                {/* <Route
                    path={PrivateRoutes.ACCOUNT_SETTINGS}
                    element={
                        <PrivateRoute
                            activeTabName={formatMessage({ id: "approute.tab.label-account-settings", defaultMessage: "Account Settings" })}
                            activePageName={formatMessage({ id: "approute.tab.label-account-settings", defaultMessage: "Account Settings" })}
                            activeUiTabResourceId="active-tab.name.account-settings.tab-"
                            roles={[UserRoles.Patient]}
                        >
                            <Suspense fallback={exampleSpinner}>
                                <AccountSettingsManager />
                            </Suspense>
                        </PrivateRoute>
                    }
                /> */}

                <Route
                    path={PrivateRoutes.ANALYTICS}
                    element={
                        <PrivateRoute
                            activeTabName={formatMessage({ id: "approute.tab.label-analytics", defaultMessage: "Analytics" })}
                            activePageName={formatMessage({ id: "approute.tab.label-analytics", defaultMessage: "Analytics" })}
                            organizationRoles={[
                                OrganizationRole.HEALTH_PROVIDER,
                                OrganizationRole.LAB,
                                OrganizationRole.SUPPLIER,
                                OrganizationRole.GO_TO_MARKET,
                            ]}
                            roles={[UserRoles.Manager, UserRoles.SA]}
                        >
                            <Suspense fallback={exampleSpinner}>
                                <Analytics />
                            </Suspense>
                        </PrivateRoute>
                    }
                />
                <Route
                    path={PrivateRoutes.SYSTEM_SUPPORT}
                    element={
                        <PrivateRoute
                            activeTabName={formatMessage({ id: "approute.tab.label-system-support", defaultMessage: "System Support" })}
                            activePageName={formatMessage({ id: "approute.tab.label-system-support", defaultMessage: "System Support" })}
                            organizationRoles={[
                                OrganizationRole.HEALTH_PROVIDER,
                                OrganizationRole.LAB,
                                OrganizationRole.SUPPLIER,
                                OrganizationRole.GO_TO_MARKET,
                            ]}
                            roles={[UserRoles.CS]}
                        >
                            <Suspense fallback={exampleSpinner}>
                                <SystemSupport />
                            </Suspense>
                        </PrivateRoute>
                    }
                />
                <Route
                    path={PrivateRoutes.IMPORT_AND_EXPORT}
                    element={
                        <PrivateRoute
                            activeTabName={formatMessage({ id: "approute.tab.label-import-export", defaultMessage: "Import & Export" })}
                            activePageName={formatMessage({ id: "approute.tab.label-import-export", defaultMessage: "Import & Export" })}
                            activeUiTabResourceId="active-tab.name.import-and-export.tab-"
                            organizationRoles={[
                                OrganizationRole.SUPPLIER,
                                OrganizationRole.HEALTH_PROVIDER,
                                OrganizationRole.LAB,
                                OrganizationRole.GO_TO_MARKET,
                            ]}
                            roles={[UserRoles.SA]}
                        >
                            <Suspense fallback={exampleSpinner}>
                                <ImportAndExport />
                            </Suspense>
                        </PrivateRoute>
                    }
                />
                <Route
                    path={`${PrivateRoutes.TEST_RESULTS_VIEW}/:orderId`}
                    element={
                        <PrivateRoute activeTabName={formatMessage({ id: "approute.tab.label-test-view-result", defaultMessage: "Test / view result" })}>
                            <Suspense fallback={exampleSpinner}>
                                <PersonalResultDetails />
                            </Suspense>
                        </PrivateRoute>
                    }
                />
                <Route
                    path={`${PrivateRoutes.TEST_RESULTS_VIEW_OLD}/:encounterId`}
                    element={
                        <PrivateRoute activeTabName={formatMessage({ id: "approute.tab.label-test-view-result", defaultMessage: "Test / view result" })}>
                            <Suspense fallback={exampleSpinner}>
                                <ImportedPersonalResultDetails />
                            </Suspense>
                        </PrivateRoute>
                    }
                />
                <Route
                    path={`${PrivateRoutes.APPLICATIONS}`}
                    element={
                        <PrivateRoute
                            activeTabName={formatMessage({ id: "approute.tab.label-applications", defaultMessage: "Applications" })}
                            activePageName={formatMessage({ id: "approute.tab.label-applications", defaultMessage: "Applications" })}
                            organizationRoles={[
                                OrganizationRole.HEALTH_PROVIDER,
                                OrganizationRole.LAB,
                                OrganizationRole.SUPPLIER,
                                OrganizationRole.GO_TO_MARKET,
                            ]}
                            roles={[UserRoles.SA, UserRoles.CS, UserRoles.Employee, UserRoles.Manager, UserRoles.Proctor, UserRoles.SA, UserRoles.Staff]}
                        >
                            <Suspense fallback={exampleSpinner}>
                                <Applications />
                            </Suspense>
                        </PrivateRoute>
                    }
                />
                <Route
                    path={PrivateRoutes.TENANT_SETTINGS}
                    element={
                        <PrivateRoute
                            activeTabName={formatMessage({ id: "approute.tab.label-settings", defaultMessage: "Settings" })}
                            activePageName={formatMessage({ id: "approute.tab.label-settings", defaultMessage: "Settings" })}
                            // organizationRoles={[
                            //     OrganizationRole.HEALTH_PROVIDER,
                            //     OrganizationRole.LAB,
                            //     OrganizationRole.SUPPLIER,
                            //     OrganizationRole.GO_TO_MARKET,
                            // ]}
                            roles={[UserRoles.SA]}
                        >
                            <Suspense fallback={exampleSpinner}>
                                <TenantSettings />
                            </Suspense>
                        </PrivateRoute>
                    }
                />
                <Route
                    path={PrivateRoutes.CAMPAIGNS_LIST}
                    element={
                        <PrivateRoute
                            activeTabName={formatMessage({ id: "approute.tab.label-campaigns", defaultMessage: "Campaigns" })}
                            organizationRoles={[OrganizationRole.GO_TO_MARKET]}
                            roles={[UserRoles.Proctor, UserRoles.SA]}
                        >
                            <Suspense fallback={exampleSpinner}>
                                <CampaignsListGrid />
                            </Suspense>
                        </PrivateRoute>
                    }
                />
                <Route
                    path={PrivateRoutes.CAMPAIGN_DETAILS}
                    element={
                        <PrivateRoute
                            activeTabName={formatMessage({ id: "approute.tab.label-campaigns-details", defaultMessage: "Campaigns Details" })}
                            organizationRoles={[
                                OrganizationRole.GO_TO_MARKET,
                                OrganizationRole.HEALTH_PROVIDER,
                                OrganizationRole.LAB,
                                OrganizationRole.SUPPLIER,
                            ]}
                            roles={[UserRoles.Employee, UserRoles.Manager, UserRoles.SA]}
                        >
                            <Suspense fallback={exampleSpinner}>
                                <CampaignDetailsPage />
                            </Suspense>
                        </PrivateRoute>
                    }
                />
                <Route
                    path={PrivateRoutes.PARTNERSHIPS}
                    element={
                        <PrivateRoute
                            activeTabName={formatMessage({ id: "approute.tab.label-partnerships", defaultMessage: "Partnerships" })}
                            activePageName={formatMessage({ id: "approute.tab.label-partnerships", defaultMessage: "Partnerships" })}
                            organizationRoles={[
                                OrganizationRole.HEALTH_PROVIDER,
                                OrganizationRole.LAB,
                                OrganizationRole.SUPPLIER,
                                OrganizationRole.GO_TO_MARKET,
                            ]}
                            roles={[UserRoles.SA]}
                        >
                            <Suspense fallback={exampleSpinner}>
                                {/* <PartnershipsManager /> */}
                                <PartnershipsManagement />
                            </Suspense>
                        </PrivateRoute>
                    }
                />
                <Route
                    path={PrivateRoutes.VERIFY_PAYMENT}
                    element={
                        <PrivateRoute
                            activeTabName={formatMessage({ id: "approute.tab.label-verify-payment", defaultMessage: "Verify Payment" })}
                            activePageName={formatMessage({ id: "approute.tab.label-verify-payment", defaultMessage: "Verify Payment" })}
                            activeUiTabResourceId="active-tab.name.account-settings.tab-"
                            roles={[UserRoles.Patient]}
                        >
                            <Suspense fallback={exampleSpinner}>
                                <VerifyPayment />
                            </Suspense>
                        </PrivateRoute>
                    }
                />

                <Route
                    path={PrivateRoutes.WELCOME}
                    element={
                        <PrivateRouteNotEmailVerified roles={[UserRoles.Patient, UserRoles.Proctor]}>
                            <Suspense fallback={exampleSpinner}>
                                <Welcome />
                            </Suspense>
                        </PrivateRouteNotEmailVerified>
                    }
                />

                <Route path="*" element={<NotFound />} />
            </Route>,
        ),
    );

    routes.subscribe(versionSyncListener);

    // debug
    // routes.subscribe((state) => console.log("new state", state, JSON.stringify(state.location)));

    return <RouterProvider fallbackElement={exampleSpinner} router={routes} future={{ v7_startTransition: true }} />;
};

export default AppRoute;
